import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';
import TimelineIcon from '@material-ui/icons/Timeline';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Profile, SidebarNav } from './components';
import { userInfoContext } from '../../App';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import RemoveIcon from '@material-ui/icons/Remove';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SettingsIcon from '@material-ui/icons/Settings';
import MemoryIcon from '@material-ui/icons/Memory';

import secureLocalStarage from 'react-secure-storage';

import { useRecoilValue } from 'recoil';
// import { userAccount } from '../../common/mainStore';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

import { deploy } from 'config';
import Products from '../../product';
import { unSidebarPathList } from '../../common/unSidebar';

const PAGE_UPDATE_STATE_RUNNING = 1;
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));
const DASHBOARD = {
  title: '대시보드',
  path: '/dashboard',
  name: 'dashboard',
  permission: undefined
};
const DEVICES = {
  title: '디바이스',
  path: '/device',
  name: 'device',
  permission: undefined
};
const CONVERTER = {
  title: '컨버터',
  path: '/converter',
  name: 'converter',
  permission: 5
};
const USERS = {
  title: '사용자',
  path: '/user',
  name: 'user',
  permission: undefined
};
const PUSH = {
  title: '푸시',
  path: '/push',
  name: 'push',
  permission: undefined
};
const ANNOUNCEMENT = {
  title: '공지사항',
  path: '/announcement',
  name: 'announcement',
  permission: undefined
};
const ROLE = {
  title: '권한',
  path: '/role',
  name: 'role',
  permission: undefined
};
const DORMANCY = {
  title: '휴면 사용자',
  path: '/dormancyHistory',
  name: 'inactive',
  permission: undefined
};
const DORMANCY_HISTORY = {
  title: '휴면 전환 안내',
  path: '/dormancyHistory',
  name: 'inactive dormancyHistory',
  permission: undefined
};
const DORMANCY_MANAGE = {
  title: '휴면 사용자 관리',
  path: '/dormancyUser',
  name: 'inactive management',
  permission: undefined
};
const QUERY_RECORD = {
  title: '접속 및 처리 기록',
  path: '/record',
  name: 'search log',
  permission: undefined
};
const QUERY_USER_RECORD = {
  title: '사용자 기록',
  path: '/record/user',
  name: 'search log',
  permission: undefined
};
const QUERY_FW_RECORD = {
  title: '펌웨어 기록',
  path: '/record/firmware',
  name: 'search log',
  permission: undefined
};
const SERVER = {
  title: '서버',
  path: '/server',
  name: 'server',
  permission: undefined
};
const STATISTICS = {
  title: '통계',
  path: '/statistics',
  name: 'analytics',
  permission: undefined
};
const FIRMWARE = {
  title: '펌웨어',
  path: '/firmware',
  name: 'firmware',
  permission: undefined
};
const FIRMWARE_OFFICIAL = {
  title: 'OFFICIAL',
  path: '/firmware/firmwareOfficial',
  name: 'firmware official',
  permission: undefined
};
const FIRMWARE_BS = {
  title: 'BS',
  path: '/firmware/firmwareBS',
  name: 'firmware bs',
  permission: undefined
};
const SETTING = {
  title: '설정',
  path: '/setting',
  name: 'setting',
  permission: undefined
};
const REPORT = {
  title: '보고',
  path: '/reports',
  name: 'reports',
  permission: undefined
};
const PRIVACY_AGREE = {
  title: '개인정보동의',
  path: '/privacy',
  name: 'privacy',
  permission: undefined
};
const STATISTICS_WATER = {
  title: '온수매트 통계',
  path: '/statistics/water',
  name: 'water statistics analytics',
  permission: undefined
};
const STATISTICS_ELEC = {
  title: '전기매트 통계',
  path: '/statistics/elec',
  name: 'elec statistics analytics',
  permission: undefined
};
const DEVICE_SEARCH = {
  title: '디바이스 검색',
  path: '/device/search',
  name: 'device search',
  permission: undefined
};
const DEVICE_HISTORY = {
  title: '에어원 디바이스 기록',
  path: '/device/history',
  name: 'device history',
  permission: undefined
};
const CONVERTER_LOG_LEVEL = {
  title: '로그 레벨',
  path: '/converter/log-level',
  name: 'converter log level',
  permission: undefined
};
const CONVERTER_BLACKLIST = {
  title: '블랙리스트',
  path: '/converter/blacklist',
  name: 'converter blacklist',
  permission: undefined
};
const MATE_KR_DEVICE_LOG = {
  title: '메이트 디바이스 로그',
  path: '/device/log/mate-kr',
  name: 'device log',
  permission: undefined
};
const AIRONE_DEVICE_LOG = {
  title: '에어원 디바이스 로그',
  path: '/device/log/airone',
  name: 'device log',
  permission: undefined
};
const SMARTTOK_DEVICE_LOG = {
  title: '스마트톡 디바이스 로그',
  path: '/device/log/smarttok',
  name: 'device log',
  permission: undefined
};
const UPSLEEP_INFO = {
  title: '업 수면 그래프',
  path: '/upsleep',
  name: 'upsleep',
  permission: 1
};

const naviIcons = [
  {
    name: 'dashboard',
    icon: <DashboardIcon />
  },
  {
    name: 'server',
    icon: <TimelineIcon />
  },
  {
    name: 'analytics',
    icon: <EqualizerIcon />
  },
  {
    name: 'converter',
    icon: <MemoryIcon />
  },
  {
    name: 'device',
    icon: <DeveloperBoardIcon />
  },
  {
    name: 'device history',
    icon: <DeveloperBoardIcon />
  },
  {
    name: 'user',
    icon: <PeopleIcon />
  },
  {
    name: 'inactive',
    icon: <SentimentVeryDissatisfiedIcon />
  },
  {
    name: 'push',
    icon: <TapAndPlayIcon />
  },
  {
    name: 'announcement',
    icon: <TapAndPlayIcon />
  },
  {
    name: 'firmware',
    icon: <PhonelinkSetupIcon />
  },
  {
    name: 'search log',
    icon: <BorderColorIcon />
  },
  {
    name: 'role',
    icon: <HowToRegIcon />
  },
  {
    name: 'setting',
    icon: <SettingsIcon />
  }
];

const Sidebar = props => {
  const {
    open,
    variant,
    onClose,
    prodid,
    className,
    history,
    userAccount,
    path,
    ...rest
  } = props;
  const authInfo = useContext(userInfoContext);
  const [subNavi, setSubNaviState] = useState([]);

  const getProductPolicies = (fullPolicies, productName) => {
    console.log('called getProductPolicies');
    var productPolicies = fullPolicies.filter(
      policy =>
        policy.productName.toLocaleLowerCase() === productName.toLowerCase()
    );
    return productPolicies.length > 0 ? productPolicies[0].policy : undefined;
  };

  const getMyPolicies = (userInfo, fullRoles, productName) => {
    var myRole = userInfo.userRoles;
    var myPolicy = undefined;
    console.log('called getMyPolicies');
    console.log(fullRoles);
    for (var i = 0; i < myRole.length; ++i) {
      var role = fullRoles.filter(role => role.roleId === myRole[i]);
      if (
        role.length > 0 &&
        role[0].roleName
          .toLowerCase()
          .split(' ')
          .includes(productName.toLowerCase()) === true
      ) {
        myPolicy = role[0].policy;
        break;
      }
    }
    console.log('productName : ' + productName.toLowerCase());
    console.log('myPolicy : ' + myPolicy);
    return myPolicy;
  };

  const productName = useMemo(() => {
    if (unSidebarPathList.indexOf(path) == -1) {
      return Products.filter(product =>
        path.split('-')[1]
          ? product.PRODUCT_NAME === path.split('-')[0] &&
            product.REGION_NAME === path.split('-')[1]
          : product.PRODUCT_NAME === path
      )[0].PRODUCT_NAME;
    }
    return localStorage.productName;
  }, [localStorage.productName, path]);

  const productFullPolicies = useMemo(() => {
    return getProductPolicies(
      JSON.parse(sessionStorage.getItem('fullPolicies')),
      productName
    );
  }, [productName, path, sessionStorage]);

  const myPolicies = useMemo(() => {
    return userAccount
      ? getMyPolicies(
          userAccount,
          JSON.parse(sessionStorage.getItem('fullRoles')),
          productName
        )
      : undefined;
  }, [userAccount, path]);

  const classes = useStyles();

  const getNavPage = useCallback(
    navList => {
      return navList.reduce(
        (result, { name, title, path: navPath, permission, index }) => {
          let obj = {
            name,
            title,
            href: `/${path}${navPath}`,
            permission
          };
          if (index >= 0) obj = { ...obj, subNavIndex: index };
          result.push(obj);
          return result;
        },
        []
      );
    },
    [path, subNavi]
  );

  const pages = useMemo(() => {
    let navList = [];
    switch (path) {
      case 'airone':
        navList = [
          DASHBOARD,
          SERVER,
          STATISTICS,
          { ...DEVICES, index: 0 },
          USERS,
          { ...DORMANCY, index: 1 },
          PUSH,
          FIRMWARE,
          { ...QUERY_RECORD, index: 2 },
          ROLE,
          SETTING
        ];
        break;
      case 'hydrofurnace':
      case 'nscada':
      case 'navilink':
      case 'smartplus':
        navList = [DASHBOARD, { ...FIRMWARE, index: 0 }, ROLE, SETTING];
        break;
      case 'mate-kr':
        navList = [
          DASHBOARD,
          SERVER,
          { ...STATISTICS, index: 1 },
          { ...DEVICES, index: 3 },
          { ...CONVERTER, index: 4 },
          FIRMWARE,
          { ...QUERY_RECORD, index: 2 },
          ROLE,
          REPORT,
          SETTING
        ];
        break;
      case 'mate-us':
        navList = [
          DASHBOARD,
          SERVER,
          STATISTICS,
          DEVICES,
          USERS,
          DORMANCY,
          PUSH,
          FIRMWARE,
          QUERY_RECORD,
          ROLE,
          REPORT,
          SETTING
        ];
        break;
      case 'smarttok':
        navList = [
          DASHBOARD,
          SERVER,
          STATISTICS,
          DEVICES,
          USERS,
          { ...DORMANCY, index: 0 },
          { ...FIRMWARE, index: 1 },
          QUERY_RECORD,
          ROLE,
          SETTING
        ];
        break;
      case 'smart':
        navList = [
          DASHBOARD,
          USERS,
          { ...DEVICES, index: 0 },
          PRIVACY_AGREE,
          QUERY_RECORD,
          PUSH,
          ANNOUNCEMENT,
          ROLE,
          SETTING
        ];
        break;
    }
    return getNavPage(navList);
  }, [path]);

  const getSubNavState = useCallback(
    subNavList => {
      return subNavList.reduce((result, nav, index) => {
        result.push({
          index,
          openState: false,
          subNav: nav.reduce((sub, { title, path: navPath, name }) => {
            sub.push({
              title,
              href: `/${path}${navPath}`,
              icon: naviIcons.filter(icon => name.indexOf(icon.name) !== -1)[0]
                ?.icon ? (
                naviIcons.filter(icon => name.indexOf(icon.name) !== -1)[0].icon
              ) : (
                <RemoveIcon />
              )
            });
            return sub;
          }, [])
        });
        return result;
      }, []);
    },
    [path]
  );

  useEffect(() => {
    let subNavList = [];
    switch (path) {
      case 'airone':
        subNavList = [
          [DEVICE_SEARCH, DEVICE_HISTORY],
          [DORMANCY_HISTORY, DORMANCY_MANAGE],
          [QUERY_USER_RECORD, QUERY_FW_RECORD]
        ];
        break;
      case 'hydrofurnace':
      case 'navilink':
      case 'nscada':
      case 'smartplus':
        subNavList = [[FIRMWARE_OFFICIAL, FIRMWARE_BS]];
        break;
      case 'mate-kr':
        subNavList = [
          [DORMANCY_HISTORY, DORMANCY_MANAGE],
          [STATISTICS_WATER, STATISTICS_ELEC],
          [QUERY_USER_RECORD, QUERY_FW_RECORD],
          [DEVICE_SEARCH],
          [CONVERTER_LOG_LEVEL, CONVERTER_BLACKLIST]
        ];
        break;
      case 'smarttok':
        subNavList = [
          [DORMANCY_HISTORY, DORMANCY_MANAGE],
          [FIRMWARE_OFFICIAL, FIRMWARE_BS]
        ];
        break;
      case 'smart':
        subNavList = [[DEVICE_HISTORY, MATE_KR_DEVICE_LOG, UPSLEEP_INFO]];
        break;
    }
    setSubNaviState(getSubNavState(subNavList));
  }, [path]);

  useEffect(() => {
    if (userAccount) {
      console.log('refresh account : ' + JSON.stringify(userAccount));
      console.log('setting role and policy after setting of account');
    }
  }, [userAccount, path]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          userInfo={authInfo}
          subNaviState={subNavi}
          setSubNaviState={setSubNaviState}
          naviIcons={naviIcons}
          account={userAccount}
          productFullPolicies={productFullPolicies}
          path={path}
          myPolicies={myPolicies}
        />
        {/*<UpgradePlan />*/}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default withRouter(Sidebar);
