export default [
  {
    PRODUCT_IDX: 0,
    PRODUCT_ID: 1,
    PRODUCT_NAME: "smarttok",
    PRODUCT_IMAGE: "/images/products/smarttok.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 1,
    PRODUCT_ID: 2,
    PRODUCT_NAME: "mate",
    PRODUCT_IMAGE: "/images/products/mate_kr.png",
    REGION_NAME: "kr",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 2,
    PRODUCT_ID: 2,
    PRODUCT_NAME: "mate",
    PRODUCT_IMAGE: "/images/products/mate_us.png",
    REGION_NAME: "us",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 3,
    PRODUCT_ID: 3,
    PRODUCT_NAME: "airone",
    PRODUCT_IMAGE: "/images/products/airone.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 4,
    PRODUCT_ID: 4,
    PRODUCT_NAME: "navilink",
    PRODUCT_IMAGE: "/images/products/navilink.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 5,
    PRODUCT_ID: 5,
    PRODUCT_NAME: "smartplus",
    PRODUCT_IMAGE: "/images/products/smartplus.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 6,
    PRODUCT_ID: 6,
    PRODUCT_NAME: "smart",
    PRODUCT_IMAGE: "/images/products/smart.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 7,
    PRODUCT_ID: 7,
    PRODUCT_NAME: "hydrofurnace",
    PRODUCT_IMAGE: "/images/products/hydrofurnace.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: true
  },
  {
    PRODUCT_IDX: 8,
    PRODUCT_ID: 8,
    PRODUCT_NAME: "nscada",
    PRODUCT_IMAGE: "/images/products/nscada.png",
    REGION_NAME: "",
    PRODUCT_ENABLE: false
  }
];
