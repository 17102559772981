import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, withRouter, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { Auth } from 'aws-amplify';
import Products from '../product';
import { dispatchContext } from '../App';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { unSidebarPathList } from '../common/unSidebar';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  selectView: {
    height: '50%'
  }
}));

const getProdName = prd => {
  if (prd !== undefined) {
    if (prd.REGION_NAME !== '') {
      return `${prd.PRODUCT_NAME} (${prd.REGION_NAME})`;
    } else {
      return `${prd.PRODUCT_NAME}`;
    }
  }
};

const Topbar = props => {
  const dispatch = useContext(dispatchContext);
  const { className, onSidebarOpen, history, path, ...rest } = props;
  const classes = useStyles();

  async function awsSignout() {
    console.log('try signout');
    await Auth.signOut({ global: true });
  }

  const signout = () => {
    awsSignout()
      .then(() => {
        console.log('success sign-out');
        dispatch({ type: 'LOGOUT' });
        history.push('/');
      })
      .catch(e => {
        console.log('error sign-out : ' + JSON.stringify(e));
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, product) => {
    if (product.PRODUCT_ENABLE) {
      setSelectedIndex(index);
      setAnchorEl(null);
      localStorage.productIdx = product.PRODUCT_IDX;
      localStorage.productID = product.PRODUCT_ID;
      localStorage.productName = product.PRODUCT_NAME;
      localStorage.regionName = product.REGION_NAME;
      if (product.REGION_NAME !== '') {
        history.push(
          `/${product.PRODUCT_NAME}-${product.REGION_NAME}/dashboard`
        );
      } else {
        history.push(`/${product.PRODUCT_NAME}/dashboard`);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //history.push('/dashboard/'+localStorage.product.PRODUCT_NAME);
    if (
      unSidebarPathList.indexOf(path) == -1
    ) {
      setSelectedIndex(
        Products.filter(product =>
          path.split('-')[1]
            ? product.PRODUCT_NAME ===path.split('-')[0] && product.REGION_NAME === path.split('-')[1]
            : product.PRODUCT_NAME === path
        )[0].PRODUCT_IDX
      );
    } else {
      setSelectedIndex(localStorage.productIdx);
    }
    console.log('Topbar re-redering');
  }, [path]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img
            className={classes.imageView}
            alt="Logo"
            src="/images/logos/logo_on.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {unSidebarPathList.indexOf(path) ==
          -1 && (
          <>
            {' '}
            <List component="nav" aria-label="Device settings">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                onClick={handleClickListItem}>
                <ListItemText
                  primary={
                    <Typography variant="caption" style={{ color: 'white' }}>
                      {' '}
                      {getProdName(Products[selectedIndex])}{' '}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {Products.map((product, index) => (
                <MenuItem
                  key={product.PRODUCT_ID + product.REGION_NAME}
                  selected={index === selectedIndex}
                  onClick={event => handleMenuItemClick(event, index, product)}>
                  {getProdName(product)}
                </MenuItem>
              ))}
            </Menu>
            <Hidden mdDown>
              <IconButton color="inherit">
                <Badge color="primary" variant="dot">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={signout}>
                <InputIcon />
              </IconButton>
            </Hidden>
            <Hidden lgUp>
              <IconButton color="inherit" onClick={onSidebarOpen}>
                <MenuIcon />
              </IconButton>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={signout}>
                <InputIcon />
              </IconButton>
            </Hidden>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
