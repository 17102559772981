import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  NlbChart,
  DeviceAreaDialog,
  ServerChart,
  ModelCounter,
  DynamoChart,
  ErrorChart
} from './components';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION
} from '../../Common/Config/naviwatchConfig';
import { userInfoContext } from '../../../App';
import UserTableDialog from './components/UserTableDialog';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Dashboard = ({ match, history }) => {
  const classes = useStyles();
  const [deviceAreaDialogOpen, setDeviceAreaDialogOpen] = useState(false);
  const [deviceAreaData, setDeviceAreaData] = useState({});
  const [userAreaDialogOpen, setUserAreaDialogOpen] = useState(false);
  const [userAreaData, setUserAreaData] = useState({});

  const authInfo = useContext(userInfoContext);
  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const [permission, setPermission] = useState(NO_PERMISSION);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('dashboard')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  const openDeviceArea = areaData => {
    setDeviceAreaData(areaData);
    setDeviceAreaDialogOpen(true);
  };

  const openUserArea = userData => {
    setUserAreaData(userData);
    setUserAreaDialogOpen(true);
  };

  const closeDeviceArea = () => {
    setDeviceAreaDialogOpen(false);
  };

  const closeUserArea = () => {
    setUserAreaDialogOpen(false);
  };
  useEffect(() => {
    console.log('dashboard rendering');
    if (sessionStorage.getItem('naviwatch_permission') !== 'true')
      history.push('/');
  }, []);

  return (
    <div className={classes.root}>
      {permission >= READ_PERMISSION ? (
        <Grid container spacing={4}>
          <Grid item lg={2} md={6} xl={6} xs={12}>
            <ModelCounter status={'online'} />
          </Grid>
          <Grid item lg={2} md={6} xl={6} xs={12}>
            <ModelCounter status={'all'} />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <ErrorChart metricName="Error" />
          </Grid>
          <Grid item lg={3} md={6} xl={3} xs={12}>
            <NlbChart metricName="NlbChart" />
          </Grid>
          <Grid item lg={3} md={6} xl={3} xs={12}>
            <DynamoChart metricName="DynamoChart" />
          </Grid>
          <DeviceAreaDialog
            openStatus={deviceAreaDialogOpen}
            closeFunc={closeDeviceArea}
            data={deviceAreaData}
          />
          <UserTableDialog
            openStatus={userAreaDialogOpen}
            closeFunc={closeUserArea}
            data={userAreaData}
          />
        </Grid>
      ) : (
        <Typography>{noPermissionMsg}</Typography>
      )}
    </div>
  );
};

export default Dashboard;
