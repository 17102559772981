import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Checkbox, Input, Backdrop, CircularProgress, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import { firmwareGetGroupUsersMQTT, firmwareModifyMQTT, getFWType, getS3Path } from '../../../APIs/aironeApis';
import useAsync from '../../../../../asyncNet';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const STRING_TITLE = '펌웨어 수정';
const STRING_MAJOR_VERSION = 'MAJOR 버전 :';
const STRING_MINOR_VERSION = 'MINOR 버전 :';
const STRING_FIRMWARE_BUILD_NUMBER = '빌드 넘버 :';
const STRING_FIRMWARE_TYPE = 'FW Type :';
const STRING_MODEL_CODE = '모델 코드 :';
const STRING_ACTIVE = 'ACTIVE 유무 :';
const STRING_FORCE = 'FORCE 유무 :';
const STRING_GROUP_OTA = 'GROUP OTA 유무 :';
const STRING_ADD_GROUP = '그룹 추가';
const STRING_FW_DESCRIPTION = '펌웨어 설명 :';
const STRING_ALERT_FW_UPLOAD = '수정 사항을 한번 더 확인해주세요.';
const STRING_CLOSE = '취소';
const STRING_MODIFY = '수정';
const GROUP_EMPTY_WARNING = "ACTIVE 상태에서 Group OTA를 설정할 경우 Group User는 반드시 등록해야 합니다.";
const GROUP_NON_JSON_WARNING = "JSON 값이 비정상입니다.";
const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  clipboardBtn:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1)

  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const placeHolderValue = [
  {
    "USERNAME": "Sample1"
  },
  {
    "USERNAME": "Sample2"
  }
];

const ModifyFirmwareDialogMqtt  = props => {
  const { open, ipAddress, handleClose, refetch, selectedFW } = props;
  const [loading, setLoading] = useState(false);
  const [forcedflag, setForcedFlag] = useState(false);
  const [activeflag, setActiveFlag] = useState(false);
  const [groupOtaflag, setGroupOtaFlag] = useState(false);
  const [fwDescription, setFwDescription] = useState("");
  const [ updatedUserList, setUpdatedUserList ] = useState("");
  const [ warningMessage, setWarningMessage ] = useState("");
  const getUsersAtGroup = () => {
    return firmwareGetGroupUsersMQTT(selectedFW.majorVersion,
      selectedFW.minorVersion,selectedFW.buildNumber, selectedFW.modelCode);
  };
  const [state, tryRefetch] = useAsync(getUsersAtGroup, [], true);
  const { data: groupUsers } = state;

  const classes = useStyles();

  useEffect(()=>{
    console.log("selectedFW: "+JSON.stringify(selectedFW));
    setForcedFlag(selectedFW.forcedFlag);
    setActiveFlag(selectedFW.activeFlag);
    setGroupOtaFlag(selectedFW.groupOta);
    setFwDescription(selectedFW.fwDescription);
    }, [selectedFW]);

  useEffect(()=>{
    tryRefetch();
  }, [open]);

  const handleForcedFlag = () =>{
    if(forcedflag){
      setForcedFlag(false);
    }else{
      setForcedFlag(true);
    }
  };

  const handleActiveFlag = () =>{
    if(groupOtaflag && !activeflag){
      if(updatedUserList.length === 0){
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
    }else{
      setWarningMessage("")
    }
    if(activeflag){
      setActiveFlag(false);
    }else{
      setActiveFlag(true);
    }
  };

  const handleGroupOtaFlag = () =>{
    if(!groupOtaflag && activeflag){
      if(updatedUserList.length === 0){
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
    }else{
      setWarningMessage("")
    }
    if(groupOtaflag){
      setGroupOtaFlag(false);
    }else{
      setGroupOtaFlag(true);
    }
  };

  const handleCloseClick = () =>{
    setForcedFlag(false);
    setActiveFlag(false);
    setGroupOtaFlag(false);
    setFwDescription("");
    handleClose();
  };

  const refetchList = (warningMessage) => {
    setLoading(false);
    refetch(warningMessage);
  };

  const modifyFirmwareFunction = () =>{
    var groupList = null;
    var ret;
    if(updatedUserList.length>0) {
      groupList = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
    }
    console.log("groupList : "+groupList)

    ret = firmwareModifyMQTT(sessionStorage.getItem('email'), ipAddress,
      selectedFW.savedMajorVersion, selectedFW.savedMinorVersion, 
      selectedFW.savedBuildNumber, 
      selectedFW.fwType,
      selectedFW.modelCode,
      selectedFW.majorVersion, selectedFW.minorVersion,
      selectedFW.buildNumber,
      activeflag===true?1:0, forcedflag===true?1:0,
      groupOtaflag===true?1:0, fwDescription, groupList);

    setLoading(true);
    handleCloseClick()
    return ret;
  };

  const [modifyState, callModify] = useAsync(modifyFirmwareFunction, [], true);
  const {modifyLoading, data: modifyResponse} = modifyState;

  const handleModify = () => {
    callModify();
  };

  useEffect(()=> {
    console.log("#####change modifyResponse: "+JSON.stringify(modifyResponse))

    if (modifyResponse !== null && modifyResponse !== undefined){
      console.log("change modifyResponse: "+JSON.stringify(modifyResponse))
      if (modifyResponse.code === 200) {
        refetchList("펌웨어 수정이 성공하였습니다.")
      } else {
        refetchList("예상치 못한 에러가 발생하였습니다.")
        console.log("fail")
      }
    }
  }, [modifyResponse]);

  const changeFWDescription = event => {
    event.persist();
    setFwDescription(event.target.value);
  };

  const editUpdateUser = event =>{
    setUpdatedUserList(event.target.value);
  };

  useEffect(()=>{
    if(activeflag && groupOtaflag){
      if(updatedUserList.length===0){
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
      else if(updatedUserList.length>0) {
        var userArrayValue = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
        try {
          var parsedJson = JSON.parse(userArrayValue);
          var count = parsedJson.length;
          if( count >= 1){
            if(Object.keys(parsedJson[0]).includes("USERNAME")===true){
              setWarningMessage("")
            }else{
              setWarningMessage(GROUP_NON_JSON_WARNING)
            }
          }
        } catch (e) {
          setWarningMessage(GROUP_NON_JSON_WARNING)
        }
      }else {
        setWarningMessage(GROUP_EMPTY_WARNING)
      }
    }
  } ,[updatedUserList]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_FW_UPLOAD}</span></Alert>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MAJOR_VERSION}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.majorVersion}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MINOR_VERSION}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.minorVersion}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FIRMWARE_BUILD_NUMBER}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.buildNumber}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FIRMWARE_TYPE}</Typography>
            <Typography className={classes.datas} variant="h6">{getFWType(selectedFW.fwType)}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_MODEL_CODE}</Typography>
            <Typography className={classes.datas} variant="h6">{selectedFW.modelCode}</Typography>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_ACTIVE}</Typography>
            <Checkbox className={classes.icons}
                      checked={activeflag}
                      onChange={handleActiveFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FORCE}</Typography>
            <Checkbox className={classes.icons}
                      checked={forcedflag}
                      onChange={handleForcedFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_GROUP_OTA}</Typography>
            <Checkbox className={classes.icons}
                      checked={groupOtaflag}
                      onChange={handleGroupOtaFlag}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}/>
          </div>
          {groupOtaflag===true&&
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className={classes.heading}>{STRING_ADD_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                id="update-user-id"
                multiline
                fullWidth
                rows={15}
                placeholder={groupUsers!=null && groupUsers['userList'].length!==0 ?
                  JSON.stringify(groupUsers['userList'], null, 2):
                  JSON.stringify(placeHolderValue, null, 2)}
                onChange={editUpdateUser}
                value={updatedUserList}
                variant="outlined"
              />
            </AccordionDetails>
            <div className={classes.clipboardBtn}>
              <CopyToClipboard text={updatedUserList.length===0?
                groupUsers!=null && groupUsers['userList'].length!==0 ?
                  JSON.stringify(groupUsers['userList'], null, 2):
                  JSON.stringify(placeHolderValue, null, 2):updatedUserList}>
                <Button
                  variant="outlined"
                >
                  Copy to clipboard
                </Button>
              </CopyToClipboard>
            </div>
          </Accordion>}
          <Divider className={classes.divider}/>
          <div className={classes.linear}>
            <Typography variant="h6">{STRING_FW_DESCRIPTION}</Typography>
          </div>
          <TextField
            id="change-desc"
            multiline
            fullWidth
            rows={5}
            onChange={changeFWDescription}
            value={fwDescription}
            variant="outlined"
          />
          <Divider className={classes.divider}/>
          <Typography>{warningMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleModify} color="primary"
                  disabled={warningMessage.length>0}
                  autoFocus>
            {STRING_MODIFY}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModifyFirmwareDialogMqtt.propTypes = {
  className: PropTypes.string
};

export default ModifyFirmwareDialogMqtt;
