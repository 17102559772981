import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Table, TableHead, TableRow,
  TableCell, TableBody, Card, CardActions,
  InputBase, makeStyles, Backdrop, CircularProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { setFirmwareGroupDevices, getFirmwareGroupDevices } from '../../../APIs/tokapis';
import useAsync from '../../../../../asyncNet';
import TextField from '@material-ui/core/TextField';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AlertPopup from './AlertPopup';
import {
  STRING_GROUP_OTA,
  STRING_ALERT_GROUP_OTA_ENABLED,
  STRING_SUCCESS_GROUP,
  STRING_FAIL_GROUP,
  STRING_DUPLICATE_DEVICE_ID,
  STRING_CANCEL,
  STRING_CONFIRM,
  STRING_CLEAN,
  STRING_ADD
} from '../../../StringTable/StringTable';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  icons: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription: {
    marginLeft: theme.spacing(2),
    width: "75%"
  },
  button: {
    marginLeft: theme.spacing(2),
    justifyContent: "center",
    marginBottom: "16px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    justifyContent: "center",
    marginBottom: "16px",
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const GroupOTADialog = props => {
  const { open, handleClose, refetch, selectedFW } = props;
  const [state, tryRefetch] = useAsync(() => getFirmwareGroupDevices(selectedFW.version, selectedFW.swCode, selectedFW.releaseType), [], true);
  const { loading, data: groupDevices } = state;
  const [updateLoading, setUpdateLoading] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [rows, setRows] = useState([]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);

  useEffect(() => {
    if (selectedFW.version !== null && selectedFW.swCode !== null) {
      tryRefetch();
    }
  }, [selectedFW]);

  useEffect(() => {
    if (groupDevices !== null) {
      setRows(groupDevices.deviceList);
    }
  }, [groupDevices]);

  const classes = useStyles();
  let data = {}
  let did = []

  const addRow = () => {
    for (let i = 0; i <= rows.length; i++) {
      if (rows[i]) {
        did[i] = rows[i].deviceId;
        if (did.includes(deviceId) === false) {
          data = {
            id: rows.length + 1,
            deviceId: deviceId
          };
          setRows([...rows, data]);
          setDeviceId("");
        } else {
          openAlertPopup();
          setRows([...rows]);
          setDeviceId("");
        }
      }
      else {
        if (rows.length == 0) {
          data = {
            id: rows.length + 1,
            deviceId: deviceId
          };
          setRows([...rows, data]);
          setDeviceId("");
        }
      }
    }
  };

  const deleteRow = id => () => {
    let tempRows = rows.filter(row => {
      return row.id !== id + 1;
    });
    setRows(tempRows);
  };

  const handleChange = async () => {
    handleCloseClick();
    setUpdateLoading(true);
    let group = await setFirmwareGroupDevices(selectedFW.version, selectedFW.swCode, rows, selectedFW.releaseType, refetchList);
    setUpdateLoading(false);
    if (group.msg === 'SUCCESS') {
      openAlertSuccess();
    }
    else {
      openAlertFail();
    }
  };

  const handleValueChange = (event) => setDeviceId(event.target.value);
  const handleCloseClick = () => handleClose();
  const openAlertPopup = () => setDuplicateOpen(true);
  const closeAlertPopup = () => setDuplicateOpen(false);
  const refetchList = () => refetch();
  const openAlertSuccess = () => setSuccessOpen(true);
  const closeAlertSuccess = () => setSuccessOpen(false);
  const openAlertFail = () => setFailOpen(true);
  const closeAlertFail = () => setFailOpen(false);
  const allDeleteRow = () => setRows([]);


  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color={'primary'} />
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_GROUP_OTA}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_GROUP_OTA_ENABLED}</span></Alert>
          <Card className={classes.root}>
            <CardActions className={classes.button}>
              <TextField
                id="deviceId"
                label="Device ID (12 또는 16자)"
                value={deviceId}
                onChange={handleValueChange}
              />
              <Button variant="contained" color="primary" onClick={addRow} disabled={deviceId === "" || !(deviceId.length === 12 || deviceId.length === 16)}>{STRING_ADD}</Button>
              <Button variant="contained" color="primary" onClick={allDeleteRow} disabled={rows.length === 0}>{STRING_CLEAN}</Button>
            </CardActions>
            {rows.length !== 0 && (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align={'center'}><span><b>{"No"}</b></span></TableCell>
                      <TableCell align={'center'}><span><b>{"Device ID"}</b></span></TableCell>
                      <TableCell align={'center'}><span><b>{"Delete"}</b></span></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((d, i) => (
                      <TableRow key={i}>
                        <TableCell align={'center'}>
                          {i + 1}
                        </TableCell>
                        <TableCell align={'center'}>
                          <InputBase
                            type="text"
                            name="deviceId"
                            value={d.deviceId}
                            inputProps={{ 'aria-label': 'naked' }}
                          />
                        </TableCell>
                        <TableCell align={'center'}>
                          <Button onClick={deleteRow(i)}>
                            <RemoveCircleOutlineIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CANCEL}
          </Button>
          <Button onClick={handleChange} color="primary" autoFocus>
            {STRING_CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertPopup open={duplicateOpen} handleClose={closeAlertPopup} comment={STRING_DUPLICATE_DEVICE_ID} color={'red'} />
      <AlertPopup open={successOpen} handleClose={closeAlertSuccess} comment={STRING_SUCCESS_GROUP} color={'green'} severity={'success'} />
      <AlertPopup open={failOpen} handleClose={closeAlertFail} comment={STRING_FAIL_GROUP} color={'red'} severity={'error'} />
    </div>
  );
};

GroupOTADialog.propTypes = {
  className: PropTypes.string
};

export default GroupOTADialog;
