import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 300
  },
  labelText: {
    color: '#aaaaaa'
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const STRING_USER_SEQ = 'User Seq';
const STRING_USER_ID = 'User Id';
const STRING_SEARCH = '검색';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_TITLE = '온수매트 업 수면 모드 정보 검색';
// const STRING_SUB_TITLE = '업 수면 모드를 진행한 userSeq를 입력하세요.';
const STRING_SUB_TITLE = (
  <pre>
    userSeq | userId
    <br />
    -------------------
    <br />
    368 | asleep01
    <br />
    369 | asleep02
    <br />
    372 | asleep03
    <br />
    370 | Asleep04
    <br />
    371 | asleep05
    <br />
  </pre>
);
const UpSleepSensorToolbar = props => {
  const { className, setLoadingAnimation, getSessionIdList, ...rest } = props;

  const classes = useStyles();
  const [values, setValues] = useState({
    userSeq: '',
    from: '',
    to: '',
    userId: '',
    eventTypeList: [false, false]
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const cleanSearchInput = () => {
    setValues({
      userSeq: '',
      from: '',
      to: '',
      userId: '',
      eventTypeList: [false, false]
    });
  };

  const handleCheckAllChange = event => {
    setValues({
      ...values,
      eventTypeList: [event.target.checked, event.target.checked]
    });
  };

  const handleCheckSuccessChange = event => {
    setValues({
      ...values,
      eventTypeList: [event.target.checked, values.eventTypeList[1]]
    });
  };

  const handleCheckFailChange = event => {
    setValues({
      ...values,
      eventTypeList: [values.eventTypeList[0], event.target.checked]
    });
  };
  const checkBoxChildren = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="세션 성공"
        control={
          <Checkbox
            checked={values.eventTypeList[0]}
            onChange={handleCheckSuccessChange}
          />
        }
      />
      <FormControlLabel
        label="세션 실패"
        control={
          <Checkbox
            checked={values.eventTypeList[1]}
            onChange={handleCheckFailChange}
          />
        }
      />
    </Box>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={STRING_TITLE} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6}>
            <TextField
              fullWidth
              label={STRING_USER_SEQ}
              margin="dense"
              name="userSeq"
              onChange={handleChange}
              value={values.userSeq}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              fullWidth
              label={STRING_USER_ID}
              margin="dense"
              name="userId"
              onChange={handleChange}
              value={values.userId}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <form className={classes.container} noValidate>
              <TextField
                id="from"
                name="from"
                label="From"
                type="datetime-local"
                className={classes.textField}
                value={values.from}
                onChange={handleChange}
                disabled={values.condition === ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
          <Grid item md={3} xs={6}>
            <form className={classes.container} noValidate>
              <TextField
                id="to"
                name="to"
                label="To"
                type="datetime-local"
                className={classes.textField}
                value={values.to}
                onChange={handleChange}
                disabled={values.condition === ''}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </form>
          </Grid>
          <Grid item md={3} xs={6}>
            <FormControlLabel
              label="전체 세션"
              control={
                <Checkbox
                  checked={values.eventTypeList[0] && values.eventTypeList[1]}
                  indeterminate={
                    values.eventTypeList[0] !== values.eventTypeList[1]
                  }
                  onChange={handleCheckAllChange}
                />
              }
            />
            {checkBoxChildren}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={
            (!values.userSeq || !values.userId) && !values.from && !values.to
          }>
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={
            !values.from ||
            !values.to ||
            (!values.eventTypeList[0] && !values.eventTypeList[1])
          }
          onClick={() => getSessionIdList(values)}>
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

UpSleepSensorToolbar.propTypes = {
  className: PropTypes.string
};

export default UpSleepSensorToolbar;
