import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CellWifiTwoToneIcon from '@material-ui/icons/CellWifiTwoTone';
import PortableWifiOffTwoToneIcon from '@material-ui/icons/PortableWifiOffTwoTone';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  ListItemText,
  ListItem,
  Typography
} from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import useAsync from '../../../../../asyncNet';
import Alert from '@material-ui/lab/Alert';
import {deviceDetailSearch, jobCompleteHistory} from '../../../APIs/aironeApis';
import ReportDialog from './ReportDialog';
import TextField from '@material-ui/core/TextField';

const STRING_DEVICE_ID = '디바이스ID';
const STRING_USER_ID = '소유자 아이디';
const STRING_SW_MAJOR_VERSION = 'SW MAIN 버전';
const STRING_SW_MINOR_VERSION = 'SW SUB 버전';
const STRING_REGION = '지역';
const STRING_MODEL = '모델';
const STRING_SSID = 'SSID';
const STRING_INFO_DEVICE = '디바이스 정보입니다.';
const STRING_CONNECTIVITY = '연결상태';
const STRING_CONNECT = '연결됨';
const STRING_DISCONNECT = '비연결 ';
const STRING_DISCONNECT_TIMEINFO ='에 연결이 마지막으로 끊어졌습니다.';
const STRING_DETAIL = '상세보기';
const STRING_REPORT = '리포트';
const STRING_JOBHISTORY = 'OTA History';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  jobHistoryTextEdit:{
    width: '700px',
    whiteSpace:'pre-line'
  }

}));

const getModelName = (modelCode) => {
  var modelName = `알 수 없음 (code: ${modelCode})`;
  if (modelCode === 2)
    modelName = 'NTR10PW';
  else if (modelCode === 3)
    modelName = 'NTR10PW2ND';
  else if (modelCode === 4)
    modelName = 'NRT20D';
  else if (modelCode === 5)
    modelName = 'NRT20L';
  else if (modelCode === 6)
    modelName = 'NRT21D';
  else if (modelCode === 7)
    modelName = 'NRT20DS';
  else if (modelCode === 8)
    modelName = 'NRT20DSW';
  else if (modelCode === 1000)
    modelName = 'NRT30L';
  else if (modelCode === 1001)
    modelName = 'NRT30S';
  return modelName;
};
const valueChecker = (value) => {
  if(value === null || value === "" || value === undefined)
    return '정의되지 않음';
  else
    return value;
};

const connectInfo = (connect, time) =>{
  var infoString = '';
  if(connect === 0)
    infoString+= STRING_DISCONNECT;
  else if(connect === 1)
    infoString+= STRING_CONNECT;

  if(connect === 0) {
    infoString += ", "+new Date(time * 1000).toISOString().slice(5, 10).replace('-','월 ')+"일";
    infoString += STRING_DISCONNECT_TIMEINFO;
  }

  return infoString;
};


const DeviceTable = props => {
  const { className, devices, ...rest } = props;

  const classes = useStyles();

  const [selectedDevices] = useState([]);
  const [choiceddevice, setDeviceChoice] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [open, setOpen] = React.useState(false);



  const [state, tryRefetch] = useAsync(()=>deviceDetailSearch(choiceddevice), [], true);
  const { data: deviceInfo } = state;
  
  const [otaState, tryRefetchOta] = useAsync(()=>jobCompleteHistory(choiceddevice), [], true );
  const {data: deviceOtaInfo} = otaState

  const [reportDialogOpen, setReportDialogOpen] = React.useState(false);

  const handleReportOpen = (deviceId) => {
    console.log('handleReportOpen');
    setDeviceChoice(deviceId);
    setReportDialogOpen(true);
  };

  const handleReportClose = () => {
    setDeviceChoice('');
    setReportDialogOpen(false);
  };
  
  useEffect(() => {
    console.log('useEffect');
    if (choiceddevice !== '') {
      tryRefetch();
      tryRefetchOta();
    }
  }, [choiceddevice]);

  const handleClickOpen = (deviceId) => {
    console.log('handleClickOpen');
    setDeviceChoice(deviceId);
    setOpen(true);
   
  };

  const handleClose = () => {
    setDeviceChoice('');
    setOpen(false);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  
  const job_history = () => {
    const job_history_map_arr = otaState['data']
    const historyItems = job_history_map_arr.map((item, index) => {
      const initResult = otaState['data'][index].History_Info.ota.result 
      const color = initResult === 'succeeded' ? 'green' : 'red';
      const otaResult = color === 'green' ? 'SUCCEEDED' : 'FAILED';
      let desc = otaState['data'][index].History_Info.ota.description.toString().replace(/\\n/g, '\n');
      desc = desc.replaceAll('"','')
      return (
        <div key={index}>
          <br/>
          RC
          &nbsp;
          <span style={{fontWeight: 'bold'}}>
            {otaState['data'][index].History_Info.ota.version.major}.
            {otaState['data'][index].History_Info.ota.version.minor}.
            {otaState['data'][index].History_Info.ota.version.build}
          </span>
          &nbsp;
          ({otaState['data'][index].Date})
          &nbsp;
          <span style={{ color }}>{otaResult}</span>
          <br/>
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {desc}
          </span>
        </div>
      );
    });
    return <div>{historyItems}</div>;
  }
  

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align={'center'}><span><b>{STRING_DEVICE_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_MODEL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_USER_ID}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_CONNECTIVITY}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_DETAIL}</b></span></TableCell>
                    <TableCell align={'center'}><span><b>{STRING_REPORT}</b></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices !== null && devices !== undefined && devices.slice((page * rowsPerPage), ((page + 1) * rowsPerPage)).map(dev => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={dev.id}
                      selected={selectedDevices.indexOf(dev.id) !== -1}
                    >
                      <TableCell align={'center'}>
                        {dev.id}
                      </TableCell>
                      <TableCell align={'center'}>
                        {dev.modelName}
                      </TableCell>
                      <TableCell align={'center'}>
                        {dev.username}
                      </TableCell>
                      <TableCell align={'center'}>
                        {dev.connection=== 1? <CellWifiTwoToneIcon color={'secondary'}/> : <PortableWifiOffTwoToneIcon color={'error'}/>}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_DETAIL}>
                          <IconButton aria-label={STRING_DETAIL}
                                      onClick={() => handleClickOpen(dev.id)}>
                            <ZoomInTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align={'center'}>
                        <Tooltip title={STRING_REPORT}>
                          <IconButton aria-label={STRING_REPORT}
                                      onClick={() => handleReportOpen(dev.id)}>
                            <DescriptionIcon/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>              
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          {devices !== null && devices !== undefined &&
            <TablePagination
              component="div"
              count={devices !== null && devices !== undefined && devices.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
          />}
        </CardActions>
      </Card>
      <ReportDialog open={reportDialogOpen} handleClose={handleReportClose} device={choiceddevice}/>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">device Information</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Alert severity={'info'}><span style={{ color: 'CornflowerBlue' }}>{STRING_INFO_DEVICE}</span></Alert>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_DEVICE_ID}</b> : {deviceInfo !== null && ' '+(deviceInfo.data.macAddress + deviceInfo.data.additionalValue)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_MODEL}</b> : {deviceInfo !== null && ' '+getModelName(deviceInfo.data.modelCode)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_USER_ID}</b> : {deviceInfo !== null && ' '+valueChecker(deviceInfo.data.username)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_SW_MAJOR_VERSION}</b> : {deviceInfo !== null && ' '+valueChecker(deviceInfo.data.swMajorVersion)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_SW_MINOR_VERSION}</b> : {deviceInfo !== null && ' '+valueChecker(deviceInfo.data.swMinorVersion)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_REGION}</b> : {deviceInfo !== null && ' '+valueChecker(deviceInfo.data.area)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_SSID}</b> : {deviceInfo !== null && ' '+valueChecker(deviceInfo.data.ssid)}</span>
            <Divider className={classes.divider}/>
            <span ><b>{STRING_CONNECTIVITY}</b> : {deviceInfo !== null && ' '+connectInfo(deviceInfo.data.connected, deviceInfo.data.lastModify)}</span>
            <Divider className={classes.divider}/>
            <span><b>{STRING_JOBHISTORY}</b>: {deviceOtaInfo !== null && deviceOtaInfo !== undefined && ' '}</span>
              <ListItem alignItems="flex-start">
              <ListItemText
                secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    // color="secondary"
                  >
                    {deviceOtaInfo !== null && deviceOtaInfo !== undefined && job_history()}
                  </Typography>
                </React.Fragment>
          }
        />
      </ListItem>
          </DialogContentText>
          <form className={classes.form} noValidate>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeviceTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.array.isRequired
};

export default DeviceTable;
