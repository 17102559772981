import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Button,
  DialogTitle, Divider, Typography, makeStyles,
  Backdrop, CircularProgress,
  Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { firmwareGetGroupUsersMQTT, firmwareSetGroupUsersMQTT } from '../../../APIs/aironeApis';
import useAsync from '../../../../../asyncNet';
import TextField from '@material-ui/core/TextField';
const STRING_TITLE = 'GROUP OTA 대상 설정';

const STRING_CURRENT_GROUP = '현재 등록된 그룹';
const STRING_MODIFY_GROUP = '그룹 수정';

const STRING_ALERT_GROUP_OTA_ENABLED = 'GROUP OTA를 수행할 MAC 이나 User를 선택하세요.';
const STRING_CLOSE = '취소';
const STRING_GROUP_CHANGE = '변경';


const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  linear:{
    display: 'flex',
    alignItems: 'flex-end'
  },
  datas:{
    marginLeft: theme.spacing(2)
  },
  icons:{
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0)
  },
  fwDescription:{
    marginLeft: theme.spacing(2),
    width: '75%'
  },
  button:{
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const GroupOTADialogMqtt  = props => {
  const { open, ipAddress, handleClose, refetch, selectedFW } = props;
  const getUsersAtGroup = () => {
    return firmwareGetGroupUsersMQTT(selectedFW.majorVersion,
      selectedFW.minorVersion, selectedFW.buildNumber, selectedFW.modelCode)
  };
  const [state, tryRefetch] = useAsync(getUsersAtGroup, [], true);
  const { loading, data: groupUsers } = state;
  const [ updateLoading, setUpdateLoading ] = useState(false);
  const [ updatedUserList, setUpdatedUserList ] = useState("");

  const placeHolderValue = [
    {
      "USERNAME": "Sample1"
    },
    {
      "USERNAME": "Sample2"
    }
  ];


  useEffect(()=>{
    tryRefetch();
  }, [open]);


  const classes = useStyles();

  const handleCloseClick = () =>{
    handleClose();
  };

  const refetchList = () => {
    setUpdateLoading(false);
    refetch();
  };

  const handleChange = () => {
    setUpdateLoading(true);
    var userArrayValue = updatedUserList.replace(/(\r\n|\n|\r)/gm, "");
    console.log("changed value : "+userArrayValue)
    firmwareSetGroupUsersMQTT(sessionStorage.getItem('email'), ipAddress, selectedFW.majorVersion,
      selectedFW.minorVersion, selectedFW.buildNumber, selectedFW.modelCode, userArrayValue, refetchList);
    handleCloseClick()
  };

  const editUpdateUser = event =>{
    setUpdatedUserList(event.target.value);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={ loading }>
        <CircularProgress color={'primary'}/>
      </Backdrop>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{STRING_TITLE}</DialogTitle>
        <DialogContent>
          <Alert severity={'error'}><span style={{ color: 'red' }}>{STRING_ALERT_GROUP_OTA_ENABLED}</span></Alert>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className={classes.heading}>{STRING_CURRENT_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                  id="current-user-id"
                  label="Current UserID"
                  multiline
                  disabled
                  fullWidth
                  rows={15}
                  defaultValue={groupUsers!=null && JSON.stringify(groupUsers['userList'], null, 2)}
                  variant="outlined"
                />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className={classes.heading}>{STRING_MODIFY_GROUP}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                  id="update-user-id"
                  multiline
                  fullWidth
                  rows={15}
                  placeholder={JSON.stringify(placeHolderValue, null, 2)}
                  onChange={editUpdateUser}
                  value={updatedUserList}
                  variant="outlined"
                />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary" autoFocus>
            {STRING_CLOSE}
          </Button>
          <Button onClick={handleChange} color="primary" autoFocus>
            {STRING_GROUP_CHANGE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GroupOTADialogMqtt.propTypes = {
  className: PropTypes.string
};

export default GroupOTADialogMqtt;
