import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import regionCode from '../../../../../common/regionCode';
import useAsync from '../../../../../asyncNet';
import {
  deviceTotalCount
} from '../../../APIs/aironeApis';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 700
  }
}));

const STRING_SEARCH = '검색';
const STRING_SEARCH_CLEAN = '초기화';
const STRING_TITLE = '에어룸콘 검색';
const STRING_DEVICE_LABEL = 'MAC 주소';
const STRING_USER_LABEL = '소유자 ID';
const STRING_USER_NAME_LABEL = '소유자 이름';
const STRING_MODEL_LABEL = '모델';
const STRING_CONNECTION_LABEL = '서버 연결여부';
const STRING_MAT_COUNT = '총 에어룸콘은 ';
const STRING_MAT_COUNT2 = '개 입니다. 아래 검색을 통해 개별 에어룸콘을 조회할 수 있습니다.';
const STRING_MODEL_NTR10PW = 'NTR10PW';
const STRING_MODEL_NTR10PW2ND = 'NTR10PW2ND';
const STRING_MODEL_NRT20D = 'NRT20D';
const STRING_MODEL_NRT20L = 'NRT20L';
const STRING_MODEL_NRT21D = 'NRT21D';
const STRING_MODEL_NRT20DS = 'NRT20DS';
const STRING_MODEL_NRT20DSW = 'NRT20DSW';
const STRING_MODEL_NRT30L = 'NRT30L';
const STRING_MODEL_NRT30S = 'NRT30S';
const STRING_CONNECTION_ON = '연결 상태';
const STRING_CONNECTION_OFF = '비연결 상태';
const STRING_REGION = '지역';

const DeviceToolbar = props => {
  const { className, callbackFunc, ...rest } = props;

  const classes = useStyles();
  const [state] = useAsync(deviceTotalCount, []);
  const { data: deviceCount } = state;

  const [values, setValues] = useState({
    deviceid: '',
    userid: '',
    username: '',
    model: '',
    connection: '',
    region:''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const states = [
    {
      value: ''
    },
    {
      value: STRING_CONNECTION_ON
    },
    {
      value: STRING_CONNECTION_OFF
    }
  ];

  const models = [
    {
      value: ''
    },
    {
      value: STRING_MODEL_NTR10PW
    },
    {
      value: STRING_MODEL_NTR10PW2ND
    },
    {
      value: STRING_MODEL_NRT20D
    },
    {
      value: STRING_MODEL_NRT20L
    },
    {
      value: STRING_MODEL_NRT21D
    },
    {
      value: STRING_MODEL_NRT20DS
    },
    {
      value: STRING_MODEL_NRT20DSW
    },
    {
      value: STRING_MODEL_NRT30L
    },
    {
      value: STRING_MODEL_NRT30S
    }
  ];

  const cleanSearchInput = () =>{
    setValues({
      searchcond: '',
      userid: ''
    });
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader
        title={STRING_TITLE}
        subheader={STRING_MAT_COUNT + (deviceCount === null ? 0 : deviceCount.deviceCount) + STRING_MAT_COUNT2}
      />
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_MODEL_LABEL}
              margin="dense"
              name="model"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.model}
              variant="outlined"
            >
              {models.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_CONNECTION_LABEL}
              margin="dense"
              name="connection"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.connection}
              variant="outlined"
            >
              {states.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.value}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_REGION}
              margin="dense"
              name="region"
              onChange={handleChange}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={values.region}
              variant="outlined"
            >
              {regionCode.map(option => (
                <option
                  key={option.name}
                  value={option.name}
                >
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_DEVICE_LABEL}
              margin="dense"
              name="deviceid"
              onChange={handleChange}
              value={values.deviceid}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_LABEL}
              margin="dense"
              name="userid"
              onChange={handleChange}
              value={values.userid}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
          >
            <TextField
              fullWidth
              label={STRING_USER_NAME_LABEL}
              margin="dense"
              name="username"
              onChange={handleChange}
              value={values.username}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={cleanSearchInput}
          disabled={values.userid === '' && values.deviceid === '' && values.connection === '' &&
          values.model === '' && values.region ==='' && values.username === ''}
        >
          {STRING_SEARCH_CLEAN}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => callbackFunc(values)}
          disabled={values.userid === '' && values.deviceid === '' && values.connection === '' &&
          values.model === '' && values.region === ''&& values.username === ''}
        >
          {STRING_SEARCH}
        </Button>
      </CardActions>
    </Card>
  );
};

DeviceToolbar.propTypes = {
  className: PropTypes.string
};

export default DeviceToolbar;
