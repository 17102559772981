import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { userInfoContext } from '../../../../App';
import { deploy } from 'config';
import {
  NO_PERMISSION,
  NO_PERMISSION_WARNING,
  READ_PERMISSION
} from '../../../Common/Config/naviwatchConfig';
import DeviceLogToolbar from './DeviceLogToolbar';
import DeviceLogChart from './DeviceLogChart';
import DeviceLogTable from './DeviceLogTable'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));


const DeviceLog = ({ history }) => {
  const classes = useStyles();

  const authInfo = useContext(userInfoContext);

  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [noPermissionMsg, setNoPermissionMsg] = useState('');
  const [permission, setPermission] = useState(NO_PERMISSION);
  const [logData, setLogData] = useState([]);

  const [isChartLoaded, setIsChartLoaded] = useState(false);

  useEffect(() => {
    var pageInfo = sessionStorage.getItem('pages');
    if (pageInfo !== 'undefined') {
      var pageInfoObj = JSON.parse(pageInfo);
      var pageArray = pageInfoObj.filter(page =>
        page.name.toLowerCase().includes('device')
      );
      if (pageArray !== undefined && pageArray.length > 0) {
        var page = pageArray[0];
        if (page.permission === undefined) {
          setNoPermissionMsg(NO_PERMISSION_WARNING);
        }
        setPermission(page.permission);
      }
    }
  }, [authInfo.pages]);

  useEffect(() => {
    if (sessionStorage.getItem('naviwatch_permission') !== 'true') {
      history.push('/');
    }
  }, []);

  const getHistoryData = async ({from, to, deviceSeq, side, columns, table, wheres})=> {
    setLoadingAnimation(true);
    let fromTime = from + ':00+09:00';
    let toTime = to + ':00+09:00';
    let queryDataSum = [];
    let lastEventDate = '';

    const fetch_url =
      `https://jyiynts8e3.execute-api.ap-northeast-2.amazonaws.com/naviwatch/v1.0/devices/${deviceSeq}?`;

    let recursiveFetch = true; // 계속 API 요청이 필요한지에 대한 flag
    let loopNum = 0;
    const limitNum = 100;
    let queryParameter = {
      serviceCode: 200,
      table,
      columns: columns.join(', '),
      side,
      order: 'ASC',
      startTime: fromTime,
      endTime: toTime,
      limit: limitNum
    };
    if (wheres.length>0 && wheres[0].value){
      let where= wheres.reduce((result, current, index)=>{
        if(wheres.length - 1 === index){
          result = result + current['column'] + '=' + current['value'];
        }else{
          result = result + current['column'] + '=' + current['value'] + ',';
        }
        return result;
      },'')
      queryParameter['where'] = where
    }
    while (recursiveFetch && loopNum < 3) {
      let request_url = fetch_url + new URLSearchParams(queryParameter).toString();

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      };

      try {
        const response = await fetch(request_url, requestOptions);

        if (response && response.ok) {
          const result = await response.json();
          if (result['code'] > 200){
            recursiveFetch = false;
          }
          let logList = JSON.parse(JSON.stringify(result['data']['logList'])); // 조회된 로그 데이터

          if (logList.length < 1) { // 데이터가 없는 경우 중지
            recursiveFetch = false;
          } else {
            if (logList.length < limitNum) { // limit보다 적게 들어와서 더는 요청하지 않아도 되는 경우
              recursiveFetch = false;
            }

            let removeDataNum = 0; // 직전 데이터의 마지막 시간 보다 작은 row의 개수
            console.log('last event date ' + lastEventDate)
            if (lastEventDate.length > 0) {
              for (const row of logList) {
                // 직전 데이터의 가장 마지막 시간 보다 작은 row인 경우 제거
                if (row['eventdate'] <= lastEventDate) {
                  removeDataNum++;
                  console.log('removeDataNum ' + removeDataNum);
                } else {
                  break;
                }
              }
            }

            if (removeDataNum > 0) {
              // 하나만 같을시
              if (removeDataNum === 1) {
                logList.shift();
              }
              // 여러개의 eventdate가 같은 경우
              else{
                logList.splice(0, removeDataNum);
              }
            }

            if (logList.length > 0) {
              lastEventDate = logList[logList.length - 1]['eventdate'] // eventdate의 format이 request의 format과 다름
              let fromTimeDate = new Date(
                logList[logList.length - 1]['eventdate']
              );
              fromTimeDate = fromTimeDate.setHours(fromTimeDate.getHours() + 9);
              fromTime =
                new Date(fromTimeDate).toISOString().slice(0, 19) + '+09:00';

              if (queryDataSum.length === 0) {
                queryDataSum = logList;
              } else {
                queryDataSum = queryDataSum.concat(
                  logList
                );
              }
              setLogData(queryDataSum);
            }
            loopNum++;
          }
        } else {
          setIsChartLoaded(false);
          recursiveFetch = false;
        }
      } catch (e) {
        console.log(e);
        setIsChartLoaded(false);
        recursiveFetch = false;
      }
    }
    setIsChartLoaded(true);
    setLoadingAnimation(false);
  };

  return (
    <div>
      {permission >= READ_PERMISSION ?
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={loadingAnimation}>
          <CircularProgress color={'primary'} />
        </Backdrop>
        <DeviceLogToolbar callbackFunc={getHistoryData} />
        {isChartLoaded && (
          <>
            <DeviceLogTable formatTableData={logData}/>
            <DeviceLogChart chartData={logData} />
          </>
        )}
      </div>:
      <Typography className={classes.root}>{noPermissionMsg}</Typography>
        }
    </div>
  );
};

export default DeviceLog;
